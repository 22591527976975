<template>
  <Layout>
    <HomeList v-if="true" />
    <div v-else class="d-flex justify-content-center">
      <div class="spinner-border" role="status"></div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import HomeList from '@/components/Home/HomeList'

export default {
  name: 'HomeIndexView',
  components: { Layout, HomeList },
  created() {
    const userID = this.$store.getters['user/currentUserMainStore'].id
    const storeID = this.$store.getters['user/currentUserMainStore'].storeId
    this.definirUsuarioEShopping(userID, storeID)
    this.$store.dispatch('sseconnect/startSSE')
  },
  beforeDestroy() {
    this.$store.dispatch('sseconnect/stopSSE')
  },
  methods: {
    definirUsuarioEShopping(userId, shoppingId) {
      // Despacha a action para definir userId e shoppingId e iniciar o SSE
      this.$store.dispatch('sseconnect/setUserAndShopping', {
        userId,
        shoppingId
      })
    }
  }
}
</script>
